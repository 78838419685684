<script setup lang="ts">
    import type { ProductPreview } from '~/graphql/generated';
    import type { PageProps } from '~/@types/generic';

    const props = defineProps<PageProps>();

    const { page, pageId, breadcrumbItems, breadcrumbVisibility } = usePage(props);

    const { isoLocale, locale } = useLocales();
    const { ConfigurableProduct } = useEcommerce();
    const nuxtApp = useNuxtApp();
    const { containsProduct, addToCart, cartBusy } = useCart();
    const { queryParams } = usePreview();

    type PageStateType = {
        productsError: string | null;
        shopInfo: LoadShopInfoReturnType; // UNKNOWN_TYPE
        bundleProducts: any; // UNKNOWN_TYPE
    };

    const pageStateDefaultValue: PageStateType = {
        productsError: null,
        shopInfo: [],
        bundleProducts: null,
    };

    const bundlePageState = useState<PageStateType>('bundle-page-renderer-state', () => {
        return pageStateDefaultValue;
    });

    const bundle = computed(() => {
        return page.value?.bundle;
    });
    const gallery = computed(() => {
        return bundle.value?.gallery || [];
    });
    const title = computed(() => {
        return bundle.value?.label || page.value?.base.title || '';
    });
    const description = computed(() => {
        return bundle.value?.description;
    });
    const hero = computed(() => {
        return page.value?.base.hero || null;
    });
    const price = computed(() => {
        return bundle.value?.price;
    });
    const compareAtPrice = computed(() => {
        return bundle.value?.compare_at_price;
    });
    const bundleCode = computed(() => {
        return bundle.value?.code;
    });

    const uspGallery = useUspGallery(bundleCode, page.value?.bundle?.usp);

    const bundleImage = computed(() => {
        const CDN_DOMAIN = 'https://mediahub.woom.com';
        let img: any = null;
        if (bundle.value?.cartImage) {
            img = bundle.value.cartImage;
        } else if (hero.value) {
            img = hero.value;
        }
        if (img) {
            if (img.id && img.id.indexOf('a:') === 0) {
                // local file
                const src = img?.data?.src;
                if (src) {
                    return `${src}?fm=jpg&w=400&auto=format`;
                }
            } else if (img.id.indexOf('b:') === 0) {
                const comma = '%2c';
                const baseUrl = img.data.baseUrl;
                const image: string = img.data['1to1'] ? img.data['1to1'] : img.data['3to1'] ? img.data['3to1'] : img.data.image;
                const defaultSrc = `${baseUrl}${image}`;
                const sub = defaultSrc.substring(CDN_DOMAIN.length);
                return `${CDN_DOMAIN}/cdn-cgi/image/width=400${comma}fit=scale-down${comma}f=auto${comma}metadata=none${sub}`;
            }
        }
        return '';
    });

    const condition = computed(() => {
        if (bundle.value?.lead && containsProduct(bundle.value.lead).value) {
            return undefined;
        }
        return bundle.value?.requirement;
    });

    let _configs: Record<string, IConfigurable>;
    const configs = computed(() => {
        if (!_configs) {
            _configs = Object.fromEntries(
                cmsProducts.value.map((product: CMSProduct) => {
                    return [product.handle, new ConfigurableProduct(product)];
                }),
            );
        }
        return _configs;
    });

    const bundleKeys = computed(() => {
        const bundleKey: string[] = [];
        if (configs.value) {
            for (const configKey in configs.value) {
                const selectedVariant = configs.value[configKey].selected;
                if (selectedVariant.value) {
                    bundleKey.push(selectedVariant.value.sku);
                }
            }
        }
        return bundleKey;
    });

    const addBundle = () => {
        if (bundle.value) {
            /**
             * we need to create a bundle key including all variant versions in this bundle to be unique
             */

            const attributes: any = {
                Bundle: bundle.value.label || 'Unnamed Bundle',
                _bundle: bundleCode.value,
                _bundle_price: price.value,
                _bundle_key: bundleKeys.value.join('-'),
            };
            if (bundle.value.lead) {
                attributes._bundle_lead = bundle.value.lead;
            }
            if (bundleImage.value) {
                attributes._bundle_image = bundleImage.value;
            }
            const items = [];
            if (configs.value) {
                for (const configKey in configs.value) {
                    const current = configs.value[configKey].current;
                    if (current) {
                        items.push(current);
                    } else {
                        throw new Error('cannot add undefined item to bundle');
                    }
                }
            }

            addToCart(items, attributes, 'bundle_page');
        }
    };

    const canBeOrdered = computed(() => {
        if (bundle.value?.lead && !containsProduct(bundle.value.lead).value) {
            // console.log('does not contain lead product');
            return false;
        }
        if (!configs.value) return false;

        // check if we have a requirement condition
        // selected variant for all configs + availability for all configs (we don't allow Preorder, etc..)
        const doesNotMeetRequirements = Object.entries(configs.value).some(
            ([handle, conf]) => !conf.selected.value || conf.selected.value.availabilityState !== ProductAvailability.AVAILABLE,
        );

        return !doesNotMeetRequirements;
    });

    const products = computed(() => {
        const ps = page.value?.bundle?.products.components;
        if (ps) {
            return ps;
        }
        return [];
    });

    const code = bundle.value?.code ? bundle.value?.code.replace(/[\s]/gi, '') : (Math.random() * 1234567).toString();

    const id = `${pageId.value}${code}`;

    const queryProducts = products.value
        .map((p: any) => {
            if (p.shopify) {
                const filters = [];
                for (let i = 1; i < 4; i += 1) {
                    if (p.shopify[`option${i}`]) {
                        // console.log('got shopify option', p.shopify[`option${i}`])
                        const o = p.shopify[`option${i}`];
                        filters.push({
                            key: o.name,
                            value: o.value,
                        });
                    }
                }
                return {
                    handle: p.shopify.handle,
                    filters,
                };
            }
            return null;
        })
        .filter((p: any) => !!p);

    const {
        data: bundles,
        error,
        status,
    } = await useFetch(`/api/bundle`, {
        method: 'GET',
        key: 'bundle-page',
        server: true,
        watch: false,
        query: {
            id,
            locale: locale.value,
            products: queryProducts,
            ...queryParams.value,
        },
    });

    let _processedProducts: CMSProduct[] | undefined;
    const cmsProducts = computed(() => {
        if (!bundles.value) return [];
        if (_processedProducts) return _processedProducts;

        const resProducts = bundles.value?.data?.bundle?.products;
        const bundleProducts: any[] = bundle.value?.products.components || [];
        const processedProducts = processApiCollection(unref(resProducts) as Array<Partial<ProductPreview>>, isoLocale.value, true);
        // console.log('mergedProducts', processedProducts);
        for (const product of processedProducts) {
            const found = bundleProducts.find((p) => p.shopify?.handle === product.handle);
            if (found) {
                // console.log('got order code from bundle product', f, pro.orderCode);
                product.formattedTitle = found.title || product.formattedTitle;
                product.orderCode = found.shopify?.code;
                product.description = found.description;
            }
        }
        _processedProducts = processedProducts;
        return processedProducts;
    });

    const getConfig = (product: CMSProduct) => configs.value[product.handle];

    onMounted(async () => {
        if (nuxtApp.$gtm) {
            nuxtApp.$gtm.ecommerceBundlePage(page.value, bundlePageState.value.bundleProducts);
        }
    });
</script>

<template>
    <div
        v-if="cmsProducts.length > 0"
        :class="[{ 'lg:min-h-screen-desktop': gallery.length > 0 }]">
        <atm-breadcrumbs
            :items="breadcrumbItems"
            :visibility="breadcrumbVisibility" />

        <lazy-atm-grid
            full-width
            no-margin
            class="pb-4 md:pb-8">
            <lazy-atm-heading
                v-if="title"
                level="h1"
                size="md"
                class="col-span-2 mx-6 md:col-span-12 md:mx-8 lg:sr-only">
                <renderer-html :content="woomTextFormat(title)" />
            </lazy-atm-heading>

            <!-- Full width gallery until lg -->
            <lazy-mol-gallery
                v-if="uspGallery.length > 0"
                :data="uspGallery"
                class="col-span-2 md:col-span-12 lg:col-span-8 lg:ml-8" />
            <lazy-mol-product-image
                v-else-if="gallery"
                :images="gallery as any"
                :is-bike="false"
                class="col-span-2 md:col-span-12 lg:col-span-8 lg:ml-8" />
            <div v-else>
                <lazy-atm-image :data="hero" />
            </div>

            <div class="col-span-2 mx-6 flex flex-col gap-6 max-md:pb-6 md:col-span-12 md:mx-8 lg:col-span-4">
                <lazy-atm-heading
                    v-if="title"
                    level="span"
                    size="md"
                    class="max-lg:hidden">
                    <renderer-html :content="woomTextFormat(title)" />
                </lazy-atm-heading>

                <div v-if="description">
                    <renderer-html :content="description" />
                </div>

                <div v-if="status === 'success'">
                    <template
                        v-for="product in cmsProducts"
                        :key="product.id">
                        <atm-heading
                            level="h2"
                            size="sm">
                            <span>
                                <renderer-html :content="product.formattedTitle" />
                            </span>
                        </atm-heading>

                        <div v-if="product.description">
                            <renderer-html :content="product.description" />
                        </div>
                        <lazy-org-product-option-set :config="getConfig(product)" />
                    </template>
                </div>
                <mol-product-price
                    :price="price"
                    :compare-at-price="compareAtPrice"
                    :product-price-label="page?.bundle?.productPriceLabel"
                    show-border
                    alignment="right"
                    stacked
                    size="lg" />
                <client-only>
                    <div v-if="canBeOrdered">
                        <lazy-atm-button
                            block
                            :loading="cartBusy"
                            @click="addBundle">
                            {{ $t('cta.cart.add') }}
                            <template #icon>
                                <woom-icon-add-to-cart />
                            </template>
                        </lazy-atm-button>
                    </div>
                    <div
                        v-else-if="condition"
                        class="rounded-lg bg-woom-grey p-4">
                        <renderer-html :content="condition" />
                    </div>
                    <div
                        v-else
                        class="text-woom-red">
                        One Item not available
                    </div>
                    <template #fallback>
                        <div class="flex justify-center rounded-lg bg-woom-grey p-4">
                            <atm-loader-circular />
                        </div>
                    </template>
                </client-only>
                <!-- payment icon display -->
                <atm-shop-icons icon-type="payment" />
            </div>
        </lazy-atm-grid>
    </div>

    <lazy-cms-content-controller
        v-if="page?.content"
        :data="page.content" />
</template>
